import { connect } from 'react-redux'
import NewAllocationCompany from '../../views/newAllocationCompany/NewAllocationCompany'

const Test = () => (
  <>
    {/* 
      <Button onClick={() => setMeetingRequestModalVisible(true)}>
          Open Modal
      </Button>
      <RequestOnlineMeeting
        visible={meetingRequestModalVisible}
        companyIdTo={selectedRecord.company.companyId}
        companyToName={selectedRecord.company.name}
        company={requestCompanies[1]}
        companyTo={requestCompanies[0]}
        fundId={selectedFundId}
        handleCancel={() => setMeetingRequestModalVisible(false)}
        onTimeChanged={onTimeChanged}
        onParticipantsChanged={onParticipantsChanged.bind(null, true)}
        onToParticipantsChanged={onParticipantsChanged.bind(null, false)}
        isFundManager={isManager}
        selectedDateTime={selectedDateTime}
      /> */}
    {/*
        <RescheduleEventMeeting
          meetingId={251108}
          eventId={1004}
          companyEventId={1003}
          companyId={1003}
        />
        <RequestSummitMeeting 
          eventId={1003}
          companyEventId={1908}
          companyId={1007}
          visible={summitMeetingRequestModalVisible}
          hasCredits={true}
          isFundManager={true}
          closeModal={() => setSummitMeetingRequestModalVisible(false)}
          handleOk={() => setSummitMeetingRequestModalVisible(false)}
        />
        <NewContactModal 
          companyId={1002}
          userType={"fundmanagers"}
          visible={meetingRequestModalVisible}
          handleCancel={() => setMeetingRequestModalVisible(false)}
        />
        */}
    <NewAllocationCompany />
  </>
)

const mapStateToProps = (state) => ({
  companyId: state.user.companyId,
  userId: state.auth.userId,
  userRole: 'Manager',
})

const mapDispatchToProps = {}

export default connect(mapStateToProps, mapDispatchToProps)(Test)
