import { Checkbox, Col } from 'antd'
import FloatingInput from '../FloatingInput'
import FloatingSelect from '../FloatingSelect'
import '../FloatingSelect/FloatingSelect.less'

export const renderInput = (question, handleChange) => {
  switch (question.questionType) {
    case 'Text - Single':
      return (
        <div>
          <FloatingInput name={question.text} nameField={question.shortName} />
        </div>
      )
    case 'Checkbox':
      return (
        <Col span={24} style={{ paddingLeft: '0px' }}>
          <div>
            <h5 className="heading5">{question.text}</h5>
          </div>
          <div>
            <Checkbox.Group
              name={question.shortName}
              nameField={question.shortName}
              style={{ display: 'grid' }}
              options={getOptions(
                'checkbox',
                question.managerCompanyQuestionAnswers
              )}
              //onChange={vals => setFieldValue(question.shortName, vals)}
            />
          </div>
        </Col>
      )
    case 'Dropdown':
      return (
        <FloatingSelect
          name={question.text}
          id={question.shortName}
          key={question.shortName}
          options={getOptions(
            'dropdown',
            question.managerCompanyQuestionAnswers
          )}
          onChange={handleChange}
          showArrow={true}
        />
      )
    case 'Numeric - INT':
      return (
        <Col
          span={
            question.shortName == 'CRD' || question.shortName == 'CIK' ? 12 : 24
          }
          style={{ paddingLeft: '2px', paddingRight: '2px' }}
        >
          <FloatingInput name={question.text} nameField={question.shortName} />
        </Col>
      )
    default:
      break
  }
}

export const getInitialValues = (questions) => {
  const answerIsArray = [3, 9, 10]
  const arrayofInitialstate = questions.map((q) => ({
    [q.shortName]: answerIsArray.includes(q.questionTypeId)
      ? []
      : q.questionTypeId === 7
      ? false
      : '',
  }))
  return Object.assign({}, ...arrayofInitialstate)
}

export const getOptions = (
  dropdownOrCheckbox,
  managerCompanyQuestionAnswers
) => {
  if (dropdownOrCheckbox === 'checkbox')
    return managerCompanyQuestionAnswers.map(
      (managerCompanyQuestionAnswer) => ({
        label: managerCompanyQuestionAnswer.answer,
        value: managerCompanyQuestionAnswer.managerQuestionAnswerId,
      })
    )
  else
    return managerCompanyQuestionAnswers.map(
      (managerCompanyQuestionAnswer) => ({
        value: managerCompanyQuestionAnswer.managerQuestionAnswerId,
        text: managerCompanyQuestionAnswer.answer,
      })
    )
}
