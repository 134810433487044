import { useState } from 'react'
import { faAngleDown, faPlus } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Dropdown, Menu } from 'antd'
import { NavLink } from 'react-router-dom'
import './AdditionButton.less'

const AdditionButton = (menus) => {
  const [rotate, setRotate] = useState('rotate')
  const menu = (
    <Menu className="action-menu">
      {menus.menus.map((item, index) => [
        <Menu.Item key={index} className="action-menu-item">
          <NavLink
            style={{ color: '#4D4F56' }}
            to={`${item.link}`}
            exact
            activeClassName={'cc-nav-active'}
          >
            <span className="action-menu-text">
              <FontAwesomeIcon style={{ color: '#C2C6CC' }} icon={item.icon} />{' '}
              {item.text}
            </span>
          </NavLink>
        </Menu.Item>,
        index < menus.menus.length - 1 ? (
          <Menu.Divider className="action-menu-divider" />
        ) : (
          <></>
        ),
      ])}
    </Menu>
  )
  const rotateHandle = () => {
    setRotate('rotate.down')
  }
  return (
    <Dropdown trigger="hover" overlay={menu} placement="bottomRight">
      <Button type="primary" size={'large'} onClick={rotateHandle}>
        <span>
          <FontAwesomeIcon className="investors-columns-icon" icon={faPlus} />
          {'  '}
          Create
          {'  '}
          <FontAwesomeIcon className={rotate} icon={faAngleDown} />
        </span>
      </Button>
    </Dropdown>
  )
}

export default AdditionButton
