import { useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import { Col, Divider, PageHeader, Row } from 'antd'
import { getUserList } from '../../actions/users'
import ContextTable from '../../components/ContextTable'
import { getUserColumns } from '../../utils/columns'

const Users = () =>
  //extends Component
  {
    const [userData, setUsersData] = useState({})
    const [refreshData, setRefreshData] = useState(false)
    const [columns, setColumns] = useState(null)

    const refresh = () => {
      setRefreshData(!refreshData)
    }

    useEffect(() => {
      var cols = getUserColumns(refresh)

      setColumns(cols)
    }, [refreshData])

    return (
      <div style={{ marginTop: '20px' }}>
        <Row style={{ paddingBottom: '24px' }}>
          <Col span={12}>
            <h1
              className="cc-section-header-title"
              style={{ lineHeight: '30px' }}
            >
              {'Users'}
            </h1>
          </Col>
          <Col span={12} style={{ textAlign: 'right' }}></Col>
        </Row>
        <Row>
          <Col span={24}>
            {columns && (
              <ContextTable
                tableTitle="Users"
                columns={columns}
                dataMethod={getUserList}
                scroll={{ x: 1900 }}
                refresh={refreshData}
                refreshData={refreshData}
              />
            )}
          </Col>
        </Row>
      </div>
    )
  }

Users.propTypes = {}

export default Users
