import { useEffect, useState } from 'react'
import { faArrowLeft } from '@fortawesome/pro-light-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Button, Col, Input, Row, message } from 'antd'
import { useHistory } from 'react-router-dom'
import BetterFloatingInput from '../../components/BetterFloatingInput/BetterFloatingInput'
import FloatingInputNumber from '../../components/FloatingInputNumber/FloatingInputNumber'
import FloatingMultiSelect from '../../components/FloatingMultiSelect/FloatingMultiSelect'
import FloatingSelect from '../../components/FloatingSelect/FloatingSelect'
import UploadFileField from '../../components/Forms/Fields/UploadFileField'
import FundCompanyUsers from '../../components/FundCompanyUsers/FundCompanyUsers'
import OnboardingAddressSection from '../../components/OnboardingComponents/OnboardingAddressSection'
import { BASE_URL } from '../../config'
import http from '../../utils/api'

const NewFundCompany = () => {
  const { push } = useHistory()
  const [data, setData] = useState({})
  const [fundQuestions, setfundQuestions] = useState([])
  const [addressValue, setAddressValue] = useState({})
  const [selectedManagers, setSelectedManagers] = useState([])
  const [loading, setLoading] = useState(false)
  useEffect(() => {
    setLoading(true)
    http
      .get('funds/questions/fundcompanies')
      .then((response) => {
        setfundQuestions(response.data)
      })
      .finally(() => setLoading(false))
  }, [])

  const updatefundData = (field, value) => {
    const newState = { ...data }
    newState[field] = value
    setData(newState)
  }

  const addressChanged = (companyValue) => {
    companyValue.postalCode = companyValue.zipCode
    setAddressValue(companyValue)
  }

  const saveChanges = () => {
    setLoading(true)
    const company = {}
    company['name'] = data['name']
    company['phoneNumber'] = data['phoneNumber']
    company['companyWebsite'] = data['companyWebsite']
    Object.keys(addressValue).forEach(function (key) {
      company[key] = addressValue[key]
    })
    Object.keys(data).forEach(function (key) {
      if (key !== 'name') company[key] = data[key]
    })
    company['contacts'] = selectedManagers
    http
      .post('companies/newmanagercompany', company)
      .then((response) => {
        if (response.status === 200) {
          message.success('Successfully created new Fund company!')
          push('/')
        } else {
          message.error('Something went wrong, please try again!')
        }
      })
      .catch(() => {
        message.error('Something went wrong, please try again!')
      })
      .finally(() => setLoading(false))
  }

  const handleMultiSelectChange = (dataField, e, updateFunction) => {
    if (dataField === 'secondaryInvestorCategory') {
      if (e.length > 2) {
        return
      }
    }
    if (e.length > 0) updateFunction(dataField, e.join(','))
    else updateFunction(dataField, null)
  }

  const questionRows = (type, questions) => {
    const questionField = 'managerCompanyQuestionAnswers'
    const update = updatefundData

    return questions.map((q) => {
      const dataField =
        q.shortName.substring(0, 1).toLowerCase() + q.shortName.substring(1)
      let answers = []

      if (q.questionTypeId === 7) {
        answers = [
          { text: 'Yes', value: true },
          { text: 'No', value: false },
        ]
        return (
          <Col span={24}>
            <FloatingSelect
              name={q.name}
              nameField={q.shortName}
              options={answers}
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      } else if (q.questionTypeId === 3) {
        answers = q[questionField].map((item) => ({
          text: item.answer,
          value: item.answer,
        }))

        let checked = null
        if (data[dataField] !== undefined) checked = data[dataField]

        if (checked !== null && checked !== undefined) {
          checked = checked.split(',')
        }

        return (
          <Col span={24}>
            <FloatingMultiSelect
              value={checked}
              name={q.name}
              nameField={q.shortName}
              options={answers}
              onChange={(e) => {
                handleMultiSelectChange(dataField, e, update)
              }}
            />
          </Col>
        )
      } else if (q.questionTypeId === 4) {
        answers = q[questionField].map((item) => ({
          text: item.answer,
          value: item.answer,
        }))

        return (
          <Col span={24}>
            <FloatingSelect
              name={q.name}
              nameField={q.shortName}
              options={answers}
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      } else if (q.questionTypeId === 5) {
        return (
          <Col span={24}>
            <FloatingInputNumber
              name={q.name}
              nameField={q.shortName}
              min={0}
              type="currency"
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      } else if (q.questionTypeId === 6) {
        return (
          <Col span={24}>
            <FloatingInputNumber
              name={q.name}
              nameField={q.shortName}
              min={0}
              max={100}
              type="percentage"
              onChange={(e) => {
                update(dataField, e)
              }}
            />
          </Col>
        )
      }
    })
  }

  return (
    <Row type="flex" justify="space-between">
      <Col span={11} style={{ display: 'flex' }}>
        <Button href="/" style={{ marginRight: '10px', padding: '0px 10px' }}>
          <FontAwesomeIcon icon={faArrowLeft} />
        </Button>
        <h1 className="cc-section-header-title" style={{ lineHeight: '30px' }}>
          {'Create Fund Company'}
        </h1>
      </Col>
      <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
        <Col span={12} offset={6} style={{ padding: '20px' }}>
          <Col span={24}>
            <h2
              style={{
                marginBottom: '56px',
                marginTop: '23px',
                textAlign: 'center',
                fontWeight: '600',
              }}
            >
              Create Fund Company
            </h2>
            <BetterFloatingInput
              value={data['name']}
              name="Company Name"
              nameField="name"
              onChange={(e) => {
                updatefundData('name', e.target.value)
              }}
            />
            <Input.TextArea
              name="Company Description"
              nameField="description"
              autoSize={{ minRows: 6, maxRows: 6 }}
              value={data['description']}
              onChange={(e) => {
                updatefundData('description', e.target.value)
              }}
              placeholder="Company description"
            />
            <h3>Logo</h3>
            <UploadFileField
              accept=".jpg, .png, .bmp"
              listType="picture"
              hint="File Types: .PNG, .JPG Max File Size: 4MB"
              action={`${BASE_URL}uploads`}
              value={data['imageURL']}
              name="Logo"
              onChange={(e) => {
                updatefundData('imageURL', e)
              }}
            />
            <br />
          </Col>

          {questionRows('fund', fundQuestions)}

          <Col span={24}>
            <OnboardingAddressSection
              value={addressValue}
              inputNamePrefix="Company."
              onChange={addressChanged}
            />
          </Col>

          <Col span={24}>
            <BetterFloatingInput
              value={data['phoneNumber']}
              name="Phone Number"
              nameField="phoneNumber"
              onChange={(e) => {
                updatefundData('phoneNumber', e.target.value)
              }}
            />
          </Col>

          <Col span={24}>
            <BetterFloatingInput
              value={data['companyWebsite']}
              name="Company Website"
              nameField="companyWebsite"
              onChange={(e) => {
                updatefundData('companyWebsite', e.target.value)
              }}
            />
          </Col>

          <Col span={24}>
            <FundCompanyUsers
              setSelectedManagers={setSelectedManagers}
              selectedManagers={selectedManagers}
            />
            <Button
              block
              size="large"
              style={{
                background: '#2FC5D2',
                boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.06);',
                color: 'white',
              }}
              onClick={saveChanges}
              loading={loading}
            >
              <span style={{ background: '#2FC5D2', opacity: '1' }}>
                Create Company
              </span>
            </Button>
          </Col>
        </Col>
      </Row>
    </Row>
  )
}

NewFundCompany.GeneralInfoModal = {}

export default NewFundCompany
