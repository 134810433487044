import { useEffect, useState } from 'react'
import { Button, Checkbox, Col, Divider, Row, Select } from 'antd'
import BetterFloatingInput from '../../components/BetterFloatingInput'
import http from '../../utils/api'
import '../../../src/views/newfundcompany/newfundcompany.css'

const AllocationCompanyUsers = ({ setSelectedManagers, selectedManagers }) => {
  const [allUsers, setAllUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])

  const retrieveUsers = () => {
    http.get('funds/managers/allocators').then((response) => {
      const usersObject = []
      setAllUsers(response.data)
      response.data.map((item) => {
        usersObject.push({
          value: item.contactId,
          label: `${item.firstName} ${item.lastName}`,
        })
      })
      setFilteredUsers(usersObject.slice(0, 20))
    })
  }

  useEffect(() => {
    const newManager = {
      contactId: 0,
      firstName: '',
      lastName: '',
      email: '',
      isAdministrator: false,
    }

    setSelectedManagers((m) => [...m, newManager])
    retrieveUsers()
  }, [])

  const onChange = (value) => {
    const selectedUser = allUsers.filter((obj) => obj.contactId === value)
    const checker = selectedManagers.filter((obj) => obj.contactId === value)
    if (checker.length === 0)
      if (selectedUser.length === 1) {
        setSelectedManagers((m) => [...m, selectedUser[0]])
      }
  }

  const handleSearch = (value) => {
    value = value.toLowerCase()
    if (value.length > 2) {
      const searched = allUsers.filter((obj) =>
        `${obj.firstName} ${obj.lastName}`.toLowerCase().includes(value)
      )
      const usersObject = []
      searched.map((item) => {
        usersObject.push({
          value: item.contactId,
          label: `${item.firstName} ${item.lastName}`,
        })
      })
      setFilteredUsers(usersObject)
    }
  }

  const handleCheck = (idx) => (evt) => {
    setSelectedManagers(selectedManagers.filter((s, sidx) => idx === sidx)[0])
    const newSelectedManagers = selectedManagers.map((manager, sidx) => {
      if (idx !== sidx) return manager
      return { ...manager, isAdministrator: evt.target.checked }
    })
    setSelectedManagers(newSelectedManagers)
  }

  const updateFirstName = (idx, evt) => {
    setSelectedManagers(selectedManagers.filter((s, sidx) => idx === sidx)[0])
    const newSelectedManagers = selectedManagers.map((manager, sidx) => {
      if (idx !== sidx) return manager
      return { ...manager, firstName: evt }
    })
    setSelectedManagers(newSelectedManagers)
  }

  const updateLastName = (idx, evt) => {
    setSelectedManagers(selectedManagers.filter((s, sidx) => idx === sidx)[0])
    const newSelectedManagers = selectedManagers.map((manager, sidx) => {
      if (idx !== sidx) return manager
      return { ...manager, lastName: evt }
    })
    setSelectedManagers(newSelectedManagers)
  }

  const updateEmail = (idx, evt) => {
    setSelectedManagers(selectedManagers.filter((s, sidx) => idx === sidx)[0])
    const newSelectedManagers = selectedManagers.map((manager, sidx) => {
      if (idx !== sidx) return manager
      return { ...manager, email: evt }
    })
    setSelectedManagers(newSelectedManagers)
  }

  const newEmptyFields = () => {
    const newManager = {
      contactId: 0,
      firstName: '',
      lastName: '',
      email: '',
      isAdministrator: false,
    }
    setSelectedManagers((m) => [...m, newManager])
  }

  return (
    <>
      <Row style={{ marginTop: '10px', backgroundColor: 'white' }}>
        <Col span={24}>
          <div style={{ marginTop: '32px' }}>
            <h5 className="heading5">People</h5>
            {selectedManagers.map((selectedManager, idx) => (
              <Row key={idx}>
                <Col span={12}>
                  <BetterFloatingInput
                    onChange={(evt) => {
                      updateFirstName(idx, evt.target.value)
                    }}
                    value={selectedManager.firstName}
                    name="First Name"
                    nameField="FirstName"
                  />
                </Col>
                <Col span={12}>
                  <BetterFloatingInput
                    onChange={(evt) => {
                      updateLastName(idx, evt.target.value)
                    }}
                    value={selectedManager.lastName}
                    name="Last Name"
                    nameField="LastName"
                  />
                </Col>
                <Col span={24}>
                  <BetterFloatingInput
                    onChange={(evt) => {
                      updateEmail(idx, evt.target.value)
                    }}
                    value={selectedManager.email}
                    name="Email Address"
                    nameField="Email"
                  />
                </Col>

                <Col span={10} style={{ fontSize: '14px' }}>
                  <Checkbox onChange={handleCheck(idx)}>
                    Administrator Access
                  </Checkbox>
                </Col>
                <Col
                  span={8}
                  offset={6}
                  style={{ textAlign: 'right', fontSize: '14px' }}
                >
                  <Button
                    style={{
                      border: '0',
                      color: '#D62B34',
                      fontWeight: '600',
                      height: '21px',
                    }}
                    onClick={() =>
                      setSelectedManagers(
                        selectedManagers.filter((s, sidx) => idx !== sidx)
                      )
                    }
                  >
                    Delete
                  </Button>
                </Col>

                <Col span={24}>
                  <Divider style={{ marginTop: '16px' }} />
                </Col>
              </Row>
            ))}
            <Row>
              <Col span={9}>
                <Button onClick={newEmptyFields} className="buttonRectangle">
                  <span className="buttonLabel">Add Another User</span>
                </Button>
              </Col>
              <Col span={9} offset={6}>
                <Select
                  showSearch
                  className="searchbox"
                  placeholder="+ Add Existing User"
                  optionFilterProp="children"
                  onSelect={onChange}
                  onSearch={handleSearch}
                  filterOption={false}
                  value="+ Add Existing User"
                  showArrow={false}
                >
                  {filteredUsers.map((user, key) => (
                    <Select.Option key={key} value={user.value}>
                      {user.label}
                    </Select.Option>
                  ))}
                </Select>
              </Col>
            </Row>
            <hr style={{ border: '1px solid #EFF1F4', marginTop: '16px' }} />
          </div>
        </Col>
      </Row>
    </>
  )
}

export default AllocationCompanyUsers
