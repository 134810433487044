import { Component } from 'react'
import { faArrowLeft } from '@fortawesome/pro-regular-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  Button,
  Checkbox,
  Col,
  Divider,
  Form,
  Input,
  Row,
  Select,
  message,
} from 'antd'
import { renderInput } from '../../components/CompaniesDynamicForm/CompaniesDynamicForm'
import FloatingInput from '../../components/FloatingInput'
import UploadFileField from '../../components/Forms/Fields/UploadFileField'
import OnboardingAddressSection from '../../components/OnboardingComponents/OnboardingAddressSection'
import { BASE_URL } from '../../config'
import http from '../../utils/api'
import '../newServiceProviderCompany/newServiceProviderCompany.less'

const initialState = {
  companyManagerAnswers: [],
  contacts: [],
  questions: [],
  allUsers: [],
  filteredUsers: [],
  selectedManagers: [],
  fundAttributes: [],
  CompanyName: '',
  ServiceProviderCategory: '',
  submitButtonDisabled: true,
  loading: false,
  addressValue: {},
  imageURL: '',
  description: '',
  phoneNumber: '',
  companyWebsite: '',
}

export class NewServiceProviderCompany extends Component {
  state = initialState

  retrieveQuestions = () => {
    http.get('companies/questions/serviceprovider').then((response) => {
      this.setState({ questions: response.data })
    })
  }

  retrieveUsers = () => {
    this.setState({ loading: true })
    http.get('funds/managers/serviceproviders').then((response) => {
      const usersObject = []
      this.setState({ allUsers: response.data })
      response.data.map((item) => {
        usersObject.push({
          value: item.contactId,
          label: `${item.firstName} ${item.lastName}`,
        })
      })
      this.setState({ filteredUsers: usersObject.slice(0, 20) })
      this.setState({ loading: false })
    })
  }

  componentDidMount() {
    const newManager = {
      contactId: 0,
      firstName: '',
      lastName: '',
      email: '',
      isAdministrator: false,
    }
    this.setState({
      selectedManagers: [...this.state.selectedManagers, newManager],
    })
    this.retrieveQuestions()
    this.retrieveUsers()
  }

  filter = (inputValue, path) =>
    path.some(
      (option) =>
        option.label.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
    )

  onChange = (value) => {
    const selectedUser = this.state.allUsers.filter(
      (obj) => obj.contactId === value
    )
    const checker = this.state.selectedManagers.filter(
      (obj) => obj.contactId === value
    )
    if (checker.length === 0)
      if (selectedUser.length === 1) {
        if (this.state.selectedManagers.length === 1) {
          if (this.state.selectedManagers[0].firstName === '') {
            this.setState({
              selectedManagers: [
                ...this.state.selectedManagers.filter((s, sidx) => sidx !== 0),
                selectedUser[0],
              ],
            })
          } else {
            this.setState({
              selectedManagers: [
                ...this.state.selectedManagers,
                selectedUser[0],
              ],
            })
          }
        } else {
          this.setState({
            selectedManagers: [...this.state.selectedManagers, selectedUser[0]],
          })
        }
      }
  }

  handleSearch = (value) => {
    value = value.toLowerCase()
    if (value.length > 2) {
      const searched = this.state.allUsers.filter((obj) =>
        `${obj.firstName} ${obj.lastName}`.toLowerCase().includes(value)
      )
      const usersObject = []
      searched.map((item) => {
        usersObject.push({
          value: item.contactId,
          label: `${item.firstName} ${item.lastName}`,
        })
      })
      this.setState({ filteredUsers: usersObject })
    }
  }

  handleChange = (event) => {
    if (event.target !== undefined) {
      this.setState({ [event.target.name]: event.target.value })
    } else {
      const val = this.state.questions
        .filter((obj) => obj.shortName === 'ServiceProviderCategory')[0]
        .managerCompanyQuestionAnswers.find(
          (m) => m.managerQuestionAnswerId === parseInt(event)
        )

      this.setState({ ServiceProviderCategory: val.answer })
    }
  }

  handleCheck = (idx) => (evt) => {
    this.setState({
      selectedManagers: this.state.selectedManagers.filter(
        (s, sidx) => idx === sidx
      )[0],
    })
    const newSelectedManagers = this.state.selectedManagers.map(
      (manager, sidx) => {
        if (idx !== sidx) return manager
        return { ...manager, isAdministrator: evt.target.checked }
      }
    )
    this.setState({ selectedManagers: newSelectedManagers })
  }

  updateFirstName = (idx) => (evt) => {
    if (
      this.state.selectedManagers.filter((s, sidx) => idx === sidx)[0]
        .contactId === 0
    ) {
      this.setState({
        selectedManagers: this.state.selectedManagers.filter(
          (s, sidx) => idx === sidx
        )[0],
      })
      const newSelectedManagers = this.state.selectedManagers.map(
        (manager, sidx) => {
          if (idx !== sidx) return manager
          return { ...manager, firstName: evt }
        }
      )
      this.setState({ selectedManagers: newSelectedManagers })
    }
  }

  updateLastName = (idx) => (evt) => {
    if (
      this.state.selectedManagers.filter((s, sidx) => idx === sidx)[0]
        .contactId === 0
    ) {
      this.setState({
        selectedManagers: this.state.selectedManagers.filter(
          (s, sidx) => idx === sidx
        )[0],
      })
      const newSelectedManagers = this.state.selectedManagers.map(
        (manager, sidx) => {
          if (idx !== sidx) return manager
          return { ...manager, lastName: evt }
        }
      )
      this.setState({ selectedManagers: newSelectedManagers })
    }
  }

  updateEmail = (idx) => (evt) => {
    if (
      this.state.selectedManagers.filter((s, sidx) => idx === sidx)[0]
        .contactId === 0
    ) {
      this.setState({
        selectedManagers: this.state.selectedManagers.filter(
          (s, sidx) => idx === sidx
        )[0],
      })
      const newSelectedManagers = this.state.selectedManagers.map(
        (manager, sidx) => {
          if (idx !== sidx) return manager
          return { ...manager, email: evt }
        }
      )
      this.setState({ selectedManagers: newSelectedManagers })
    }
  }

  handleDelete = (idx) => () => {
    this.setState({
      selectedManagers: this.state.selectedManagers.filter(
        (s, sidx) => idx !== sidx
      ),
    })
  }

  handleSubmit = (e) => {
    this.setState({ loading: true })
    e.preventDefault()
    const NewSPCompanySubmit = {
      Name: this.state.CompanyName,
      Category: this.state.ServiceProviderCategory,
      Contacts: [],
      ImageURL: this.state.imageURL,
      Description: this.state.description,
      PhoneNumber: this.state.phoneNumber,
      CompanyWebsite: this.state.companyWebsite,
    }
    var addrValue = this.state.addressValue
    Object.keys(addrValue).forEach(function (key) {
      NewSPCompanySubmit[key] = addrValue[key]
    })
    this.state.fundAttributes.map((attribute) => {
      NewSPCompanySubmit.CompanyManagerAnswers.push({
        ManagerQuestionAnswerId: attribute,
      })
    })
    this.state.selectedManagers.map((manager) => {
      NewSPCompanySubmit.Contacts.push({
        ContactId: manager.contactId,
        FirstName: manager.firstName,
        LastName: manager.lastName,
        Email: manager.email,
        IsAdministrator: manager.isAdministrator,
      })
    })

    http
      .post('companies/newserviceprovider', {
        NewSPCompanySubmit,
      })
      .then((response) => {
        if (response.status === 200) {
          message.success('Successfully created new Service Provider company!')
          this.props.history.push('/')
        } else {
          message.error('Something went wrong, please try again!')
        }
      })
      .catch(() => {
        message.error('Something went wrong, please try again!')
      })
      .finally(() => this.setState({ loading: true }))
  }

  newEmptyFields = () => () => {
    const newManager = {
      contactId: 0,
      firstName: '',
      lastName: '',
      email: '',
      isAdministrator: false,
    }
    this.setState({
      selectedManagers: [...this.state.selectedManagers, newManager],
      submitButtonDisabled: true,
    })
  }

  addressChanged = (companyValue) => {
    companyValue.postalCode = companyValue.zipCode
    this.setState({ addressValue: companyValue })
  }

  render() {
    const { questions, filteredUsers } = this.state

    const formItemLayout = {
      labelCol: {
        xs: { span: 24 },
        sm: { span: 8 },
      },
      wrapperCol: {
        xs: { span: 24 },
        sm: { span: 16 },
      },
    }
    return (
      <>
        <Row type="flex" justify="space-between">
          <Col span={11} style={{ display: 'flex' }}>
            <Button
              href="/"
              style={{ marginRight: '10px', padding: '0px 10px' }}
            >
              <FontAwesomeIcon icon={faArrowLeft} />
            </Button>
            <h1
              className="cc-section-header-title"
              style={{ lineHeight: '30px' }}
            >
              {'Create Service Provider Company'}
            </h1>
          </Col>
        </Row>
        <Row style={{ marginTop: '20px', backgroundColor: 'white' }}>
          <Col span={10} offset={7} style={{ padding: '20px' }}>
            <h2
              style={{
                marginBottom: '56px',
                marginTop: '23px',
                textAlign: 'center',
                fontWeight: '600',
              }}
            >
              Create Service Provider Company
            </h2>
            <Form
              {...formItemLayout}
              onSubmit={this.handleSubmit}
              onChange={this.handleChange}
            >
              <h5 className="heading5">Company Details</h5>
              {questions.map((question) =>
                renderInput(question, this.handleChange)
              )}
              <Input.TextArea
                autoSize={{ minRows: 6, maxRows: 6 }}
                value={this.state.description}
                onChange={(e) => {
                  this.setState({ description: e.target.value })
                }}
                name="Company Description"
                nameField="Description"
                placeholder="Company description"
              />
              <h3>Logo</h3>
              <UploadFileField
                accept=".jpg, .png, .bmp"
                listType="picture"
                hint="File Types: .PNG, .JPG Max File Size: 4MB"
                action={`${BASE_URL}uploads`}
                value={this.state.imageURL}
                name="Logo"
                onChange={(e) => {
                  this.setState({ imageURL: e })
                }}
              />
              <br />

              <OnboardingAddressSection
                value={this.state.addressValue}
                inputNamePrefix="Company."
                onChange={this.addressChanged}
              />

              <FloatingInput
                value={this.state.phoneNumber}
                name="Phone Number"
                nameField="phoneNumber"
                onChange={(e) => {
                  this.setState({ phoneNumber: e })
                }}
              />

              <FloatingInput
                value={this.state.companyWebsite}
                name="Company Website"
                nameField="companyWebsite"
                onChange={(e) => {
                  this.setState({ companyWebsite: e })
                }}
              />

              <div style={{ marginTop: '32px' }}>
                <h5 className="heading5">People</h5>
                {this.state.selectedManagers.map((selectedManager, idx) => (
                  <Row key={idx}>
                    <Col span={12}>
                      <FloatingInput
                        onChange={this.updateFirstName(idx)}
                        value={selectedManager.firstName}
                        name="First Name"
                        nameField="FirstName"
                      />
                    </Col>
                    <Col span={12}>
                      <FloatingInput
                        onChange={this.updateLastName(idx)}
                        value={selectedManager.lastName}
                        name="Last Name"
                        nameField="LastName"
                      />
                    </Col>
                    <Col span={24}>
                      <FloatingInput
                        onChange={this.updateEmail(idx)}
                        value={selectedManager.email}
                        name="Email Address"
                        nameField="Email"
                      />
                    </Col>

                    <Col span={10} style={{ fontSize: '14px' }}>
                      <Checkbox onChange={this.handleCheck(idx)}>
                        Administrator Access
                      </Checkbox>
                    </Col>
                    <Col
                      span={8}
                      offset={6}
                      style={{ textAlign: 'right', fontSize: '14px' }}
                    >
                      <Button
                        style={{
                          border: '0',
                          color: '#D62B34',
                          fontWeight: '600',
                          height: '21px',
                        }}
                        onClick={this.handleDelete(idx)}
                      >
                        Delete
                      </Button>
                    </Col>

                    <Col span={24}>
                      <Divider style={{ marginTop: '16px' }} />
                    </Col>
                  </Row>
                ))}
                <Row>
                  <Col span={9}>
                    <Button
                      onClick={this.newEmptyFields()}
                      className="buttonRectangle"
                    >
                      <span className="buttonLabel">Add Another User</span>
                    </Button>
                  </Col>
                  <Col span={9} offset={6}>
                    <Select
                      showSearch
                      className="searchbox"
                      placeholder="+ Add Existing User"
                      optionFilterProp="children"
                      onSelect={this.onChange}
                      onSearch={this.handleSearch}
                      filterOption={false}
                      value="+ Add Existing User"
                      showArrow={false}
                    >
                      {filteredUsers.map((user, id) => (
                        <Select.Option key={id} value={user.value}>
                          {user.label}
                        </Select.Option>
                      ))}
                    </Select>
                  </Col>
                </Row>
                <hr
                  style={{ border: '1px solid #EFF1F4', marginTop: '16px' }}
                />
              </div>
              <Row style={{ paddingTop: '48px' }}>
                <Col span={24}>
                  <Button
                    htmlType="submit"
                    block
                    size="large"
                    style={{
                      background: '#2FC5D2',
                      boxShadow: 'inset 0px -2px 0px rgba(0, 0, 0, 0.06);',
                      color: 'white',
                    }}
                    loading={this.state.loading}
                  >
                    <span style={{ background: '#2FC5D2', opacity: '1' }}>
                      Create Company
                    </span>
                  </Button>
                </Col>
              </Row>
            </Form>
          </Col>
        </Row>
      </>
    )
  }
}
