import { Component } from 'react'
import PropTypes from 'prop-types'
import { Col, Divider, Empty, Row, Spin } from 'antd'
import { isEmpty, isNil, map } from 'lodash'
import { getPastEventsList } from '../../actions/events'
import AvailableEvent from '../../components/AvailableEvent'
import ContextTable from '../../components/ContextTable'
import EmptyState from '../../components/EmptyState'
import SectionHeader from '../../components/SectionHeader'
import http from '../../utils/api'
import { getPastSummits } from '../../utils/columns'

export class Summits extends Component {
  state = {
    availableEvents: null,
    refresh: false,
  }

  defaultSort = {
    order: 'descend',
    field: 'startDate',
    columnKey: 'startDate',
  }

  componentDidMount() {
    http.get('/upcomingevents').then((res) => {
      this.setState({ availableEvents: res.data })
    })
  }
  render() {
    const columns = getPastSummits
    const availableEventsSection = isEmpty(this.state.availableEvents) ? (
      <EmptyState message="No Available Summits for the moment" />
    ) : (
      map(this.state.availableEvents, (e) => (
        <AvailableEvent key={e.eventId} event={e} />
      ))
    )
    if (isNil(this.state.availableEvents)) {
      return (
        <Spin spinning="true">
          <Empty />
        </Spin>
      )
    }
    return (
      <>
        <Row>
          <Col span={24}>
            <SectionHeader title="Upcoming Summits" />
            {availableEventsSection}
          </Col>
        </Row>
        <Divider
          type="horizontal"
          style={{ backgroundColor: 'transparent', margin: '10px' }}
        />
        <Row>
          <Col span={24}>
            <SectionHeader title="Past Summits" />
            <ContextTable
              tableTitle="Past Summits"
              columns={columns}
              //sorter={this.defaultSort}
              dataMethod={getPastEventsList}
              refresh={this.state.refresh}
            />
          </Col>
        </Row>
      </>
    )
  }
}

Summits.propTypes = {
  push: PropTypes.func.isRequired,
}
